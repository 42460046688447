import { Badge, CommitHistory } from "@/components"
import { formatName } from "@/lib/utils"
import { Commit } from "@/models/FC"
import { useCreateCR } from "@/services/store/useCreateCR.ts"
import { usePermissions } from "@/services/store/usePermissions"
import { useTranslation } from "react-i18next"
import { User } from "@/models/User"
import { TFunction } from "i18next"

interface Props {
  commits?: Commit[]
}

const getCRState = (CreateCRTranslation: TFunction<["CreateCR" | "Common"]>, user: User | null, commits?: Commit[]) => {
  return `${formatName(user?.github_login || "")} 
    ${CreateCRTranslation("CreateCR:CRState.WantsToMerge")} 
    ${commits?.length ? commits.length + CreateCRTranslation("CreateCR:CRState.Commits") : ""} 
    ${CreateCRTranslation("CreateCR:CRState.Into")} `
}

export const BranchState = ({ commits }: Props) => {
  const { t } = useTranslation(["Common", "CreateCR"])
  const { fromBranch, intoBranch } = useCreateCR()
  const { user } = usePermissions()

  if (!fromBranch && !intoBranch) return <p className="text-xs">{t("CreateCR:SelectBranches")}</p>

  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-x-2 text-primary">
        <p className="text-sm">{getCRState(t, user, commits)}</p>
        <Badge className="px-2" variant="outline">
          {intoBranch}
        </Badge>
        <p className="text-sm">{t("From")}</p>
        <Badge className="px-2" variant="outline">
          {fromBranch}
        </Badge>
      </div>

      {commits && commits?.length > 0 ? <CommitHistory commits={commits} /> : null}
    </div>
  )
}
