import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"

export const useFetchBranches = (repositoryId?: string | null) => {
  const { error, isLoading, data } = useQuery<{ branches: string[]; defaultBranch: string }, unknown>({
    queryKey: ["branches", repositoryId],
    queryFn: async () => {
      if (repositoryId) {
        const response = await axiosClient.get(apiRoutes.branches.get(repositoryId))
        return response.data
      }
    },
    enabled: !!repositoryId
  })

  return { error, isLoading, data: data?.branches || [] }
}
