import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { GitBranch } from "lucide-react"
import { SingleSelect } from "@/components"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { getFilePathFromUrl } from "../../utils"
import { LocalStorageService } from "@/lib/localStorageService"

interface Props {
  branches: string[]
  error: unknown
  defaultValue?: string
  currentRepositoryName?: string
  setCurrentBranch: Dispatch<SetStateAction<string | undefined>>
}

const localStorageService = new LocalStorageService("localStorage")

export const BranchSelector = ({ branches, error, defaultValue, currentRepositoryName, setCurrentBranch }: Props) => {
  const [open, setOpen] = useState(false)
  const [branchName, setBranchName] = useState(defaultValue || "")
  const [searchValue, setSearchValue] = useState("")
  const { t } = useTranslation("Code")
  const { pathStart } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const disabled = !!error || branches.length <= 0 || !currentRepositoryName

  const handleSelect = useCallback(
    (branchName: string) => {
      let baseUrl = `/code/${currentRepositoryName}/${encodeURIComponent(branchName)}`

      if (pathStart) {
        const branchPath = getFilePathFromUrl(pathname)
        baseUrl += "/path/" + branchPath
      }

      localStorageService.setItem("repositoryFullName", currentRepositoryName)
      localStorageService.setItem("branch", branchName)
      navigate(baseUrl)
      setBranchName(branchName)
      setCurrentBranch(branchName)
      setOpen(false)
    },
    [setOpen, currentRepositoryName, navigate, pathStart, pathname, setCurrentBranch]
  )

  const extractOptions = useMemo(() => {
    return branches.map((branch) => ({
      value: branch,
      name: branch
    }))
  }, [branches])

  return (
    <SingleSelect
      isOpen={open}
      options={extractOptions}
      setSearchValue={setSearchValue}
      openToggle={setOpen}
      onClick={handleSelect}
      searchValue={searchValue}
      selectedValue={branchName}
      optionalParams={{
        TriggerIcon: GitBranch,
        disabled,
        emptyResultsMessage: t("NoBranchFound"),
        searchPlaceholder: t("SearchBranch"),
        withSearch: true,
        withTooltip: true,
        showByLabel: false,
        triggerElementClass: "h-10"
      }}
    />
  )
}
