import { useCreateCR } from "@/services/store/useCreateCR.ts"
import { BranchState } from "./BranchState"
import { Commit } from "@/models/FC"
import { CreateCRMenu } from "./CreateCRMenu.tsx"
import { useTranslation } from "react-i18next"
import { NewDiffResponse } from "@/hooks/queries/useFetchNewCRDiff.tsx"

interface Props {
  commits?: Commit[]
  diffLoading: boolean
  newDiff?: NewDiffResponse
}

export const CRMetadata = ({ commits, newDiff }: Props) => {
  const { name } = useCreateCR()
  const { t } = useTranslation("CreateCR")
  const isMenuDisabled = !newDiff

  return (
    <div className="bg-background">
      <div className="flex items-center justify-between">
        <h3 className={`mb-2 mt-4 text-lg font-semibold text-primary ${!name ? "italic" : ""}`}>
          {name || t("WriteCRName")}
        </h3>
        <CreateCRMenu isDisabled={isMenuDisabled} />
      </div>
      <BranchState commits={commits} />
    </div>
  )
}
