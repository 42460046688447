import { useFetchDirContent } from "@/hooks/queries/useFetchDirContent"
import { useEffect, useMemo, useState } from "react"
import { computeContentArgs, checkIfForcedOpen } from "../../../utils"
import { Loader } from "../Loader"
import { Error } from "../Error"
import { TreeNode } from "./TreeNode"
import { useLocation, useParams } from "react-router-dom"

interface Props {
  branchName?: string
  repositoryId?: string
}

export const TreeRoot = ({ repositoryId, branchName }: Props) => {
  const { pathStart } = useParams()
  const { pathname } = useLocation()
  const [pathSegments, setPathSegments] = useState<string[]>([])

  const computedFetchDirContentArgs = useMemo(
    () => computeContentArgs({ path: null, branchName, repositoryId, type: "dir" }),
    [repositoryId, branchName]
  )

  const { data, error, isLoading } = useFetchDirContent({
    fetchArgs: computedFetchDirContentArgs,
    objectType: "dir",
    shouldFetch: true
  })

  useEffect(() => {
    if (pathStart) {
      const indexOfPathStart = pathname.split("/").findIndex((segment) => segment === "path")
      const pathSegments = pathname.split("/").slice(indexOfPathStart + 1)
      setPathSegments(pathSegments)
    }
  }, [pathStart, pathname])

  if (isLoading) return <Loader />

  if (error) return <Error error={error} />

  if (!data) return <></>

  return (
    <ul className="text-xs text-primary">
      {data.entries.map((entry) => {
        const isForceOpen = pathSegments.length > 0 ? checkIfForcedOpen(pathSegments, entry) : false

        return (
          <TreeNode
            key={entry.path}
            entry={entry}
            depth={data.depth_level}
            repositoryId={repositoryId}
            branchName={branchName}
            forceOpen={isForceOpen}
            pathSegments={pathSegments.slice(1)}
          />
        )
      })}
    </ul>
  )
}
