import { ArrowLeft, ArrowRight, Bell, ListFilter, MessageCircle } from "lucide-react"
import OpenIcon from "@/assets/icons/Open.svg?react"
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components"
import { useTranslation } from "react-i18next"
import { FilterType, useDiscussions } from "@/services/store/useDiscussions"
import { cn } from "@/lib/utils.ts"
import { HEADER_HEIGHT } from "@/services/store/useChangeRequest"
import { useState } from "react"

export const DiscussionsHeader = () => {
  const { t } = useTranslation("Discussions")
  const {
    toggleExpandDiscussionsContainer,
    toggleManualRecalculationTrigger,
    discussionsExpanded,
    filteredDiscussions,
    currentFilter,
    applyFilter
  } = useDiscussions()

  const onToggle = () => {
    toggleExpandDiscussionsContainer()
    toggleManualRecalculationTrigger()
  }
  const [isSelected, setIsSelected] = useState(false)
  const isFilterActive = currentFilter !== FilterType.NONE

  return (
    <div
      id="discussions-header"
      className="sticky z-20 max-w-full border-b border-border bg-background p-3"
      style={{ top: HEADER_HEIGHT }}
    >
      <div className="flex items-center space-x-3">
        <Button
          onClick={onToggle}
          variant="outline"
          size="sm"
          className={`p-1 ${discussionsExpanded ? "size-7" : "-ml-7 size-7 rounded-full border-none bg-darker-fill hover:bg-fill"}`}
        >
          {discussionsExpanded ? <ArrowRight size={13} /> : <ArrowLeft size={13} />}
        </Button>

        <div className="grow">
          {discussionsExpanded && (
            <p className="text-xs font-semibold">{`${filteredDiscussions.length} ${t("Conversation")}${filteredDiscussions.length === 0 || filteredDiscussions.length > 1 ? "s" : ""}`}</p>
          )}
        </div>
        <DropdownMenu modal={false} onOpenChange={setIsSelected}>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="sm"
              className={cn(
                "size-7 p-1",
                "bg-background border-border hover:bg-fill",
                "focus-visible:ring-transparent",
                isFilterActive ? "text-link hover:text-link" : "text-base-primary",
                isSelected && "bg-darker-fill hover:bg-darker-fill"
              )}
            >
              <ListFilter size={20} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-48">
            <DropdownMenuItem
              className={cn("flex items-center gap-2", currentFilter === FilterType.WAITING && "bg-fill")}
              onSelect={() => applyFilter(FilterType.WAITING)}
            >
              <Bell size={16} />
              {t("OpenOnMe")}
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn("flex items-center gap-2", currentFilter === FilterType.OPEN && "bg-fill")}
              onSelect={() => applyFilter(FilterType.OPEN)}
            >
              <OpenIcon className="size-4" />
              {t("ShowOpen")}
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn("flex items-center gap-2", currentFilter === FilterType.NONE && "bg-fill")}
              onSelect={() => applyFilter(FilterType.NONE)}
            >
              <MessageCircle size={16} />
              {t("ShowAll")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
