import {
  calculateBubbleTopBasedOnCurrentDiscussions,
  isDiscussion
} from "@/components/designSystem/Discussions/utils.ts"
import { Discussion, NewCommentBubble } from "@/models/Discussions.ts"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import _ from "lodash"

export interface DiscussionWithTop extends Discussion {
  topPosition?: number
}

export enum FilterType {
  OPEN = "open",
  WAITING = "waiting",
  NONE = "none"
}

interface State {
  discussions: Array<Discussion | NewCommentBubble>
  filteredDiscussions: Array<Discussion | NewCommentBubble>
  discussionsWithTop: DiscussionWithTop[]
  manualRecalculationTrigger: boolean
  discussionsExpanded: boolean
  expandedStateBeforeComment: boolean
  currentFilter: FilterType
  toggleExpandDiscussionsContainer: () => void
  expandDiscussionsContainer: () => void
  minimizeDiscussionsContainer: () => void
  toggleManualRecalculationTrigger: () => void
  setDiscussions: (discussions: Discussion[]) => void
  setDiscussionsWithTop: () => void
  injectNewCommentBubbleToDiscussions: (newCommentBubble: NewCommentBubble) => NewCommentBubble
  removeNewCommentBubbleFromDiscussions: (newCommentBubble: Discussion | NewCommentBubble) => void
  currentHoveredDiscussion: Discussion | null
  setCurrentHoveredDiscussion: (discussion: Discussion) => void
  clearCurrentHoveredDiscussion: () => void
  currentOpenedDiscussion: Discussion | NewCommentBubble | null
  setCurrentOpenedDiscussion: (discussion: Discussion | NewCommentBubble) => void
  clearCurrentOpenedDiscussion: () => void
  updateDiscussionWithTop: (discussionId: string, top: number) => void
  applyFilter: (filterType: FilterType) => void
}

const debouncedPositionUpdate = _.debounce(
  (positions: { id: string; top: number }[], updateFn: (positions: { id: string; top: number }[]) => void) => {
    updateFn(positions)
  },
  250
)

export const useDiscussions = create<State>()(
  immer((set, get) => {
    let positionUpdates: { id: string; top: number }[] = []

    const triggerManualRecalculation = () => {
      debouncedPositionUpdate([], () => {
        set((state) => {
          state.manualRecalculationTrigger = !state.manualRecalculationTrigger
        })
      })
    }

    const expandDiscussions = () => {
      set((state) => {
        state.discussionsExpanded = true
        state.expandedStateBeforeComment = true
      })
      triggerManualRecalculation()
    }

    const filterDiscussions = (discussions: Array<Discussion | NewCommentBubble>, filterType: FilterType) => {
      switch (filterType) {
        case FilterType.OPEN:
          return discussions.filter(
            (discussion) => isDiscussion(discussion) && (discussion as Discussion).resolved_by === null
          )
        case FilterType.WAITING:
          return discussions.filter(
            (discussion) =>
              isDiscussion(discussion) &&
              (discussion as Discussion).isDiscussionWaitingForUser &&
              (discussion as Discussion).resolved_by === null
          )
        default:
          return discussions
      }
    }

    return {
      discussions: [],
      filteredDiscussions: [],
      discussionsWithTop: [],
      manualRecalculationTrigger: false,
      discussionsExpanded: false,
      expandedStateBeforeComment: false,
      currentHoveredDiscussion: null,
      currentOpenedDiscussion: null,
      currentFilter: FilterType.NONE,

      toggleExpandDiscussionsContainer: () => {
        set((state) => {
          state.discussionsExpanded = !state.discussionsExpanded
          state.expandedStateBeforeComment = !state.expandedStateBeforeComment
        })
        triggerManualRecalculation()
      },

      expandDiscussionsContainer: () => {
        set((state) => {
          state.discussionsExpanded = true
        })
      },

      minimizeDiscussionsContainer: () => {
        set((state) => {
          state.discussionsExpanded = false
        })
      },

      toggleManualRecalculationTrigger: () => {
        triggerManualRecalculation()
      },

      setDiscussions: (discussions) => {
        set((state) => {
          state.discussions = discussions
          state.filteredDiscussions = filterDiscussions(discussions, state.currentFilter)
        })
      },

      applyFilter: (filterType) => {
        set((state) => {
          state.currentFilter = filterType
          state.filteredDiscussions = filterDiscussions(state.discussions, filterType)
        })
        expandDiscussions()
      },

      setDiscussionsWithTop: () => {
        set((state) => {
          state.discussionsWithTop = get().filteredDiscussions.filter((discussion) => isDiscussion(discussion))
        })
      },

      injectNewCommentBubbleToDiscussions: (newCommentBubble) => {
        const discussions = get().filteredDiscussions
        const { bubbleIndex } = calculateBubbleTopBasedOnCurrentDiscussions(discussions, newCommentBubble)

        set((state) => {
          state.filteredDiscussions = [
            ...discussions.slice(0, bubbleIndex),
            newCommentBubble,
            ...discussions.slice(bubbleIndex)
          ]
        })

        return newCommentBubble
      },

      removeNewCommentBubbleFromDiscussions: (newCommentBubble) => {
        const discussions = get().filteredDiscussions
        const filtered = discussions.filter((discussion) => discussion.id !== newCommentBubble.id)

        set((state) => {
          state.filteredDiscussions = filtered
        })
      },

      setCurrentHoveredDiscussion: (discussion: Discussion) => {
        set((state) => {
          state.currentHoveredDiscussion = discussion
        })
      },

      clearCurrentHoveredDiscussion: () => {
        set((state) => {
          state.currentHoveredDiscussion = null
        })
      },

      setCurrentOpenedDiscussion: (discussion: Discussion | NewCommentBubble) => {
        set((state) => {
          state.currentOpenedDiscussion = discussion
        })
      },

      clearCurrentOpenedDiscussion: () => {
        set((state) => {
          state.currentOpenedDiscussion = null
        })
      },

      updateDiscussionWithTop: (discussionId: string, top: number) => {
        positionUpdates.push({ id: discussionId, top })

        debouncedPositionUpdate(positionUpdates, (updates) => {
          set((state) => {
            state.discussionsWithTop = state.discussionsWithTop.map((discussion) => {
              const discussionToUpdate = updates.find((discussionItem) => discussionItem.id === discussion.id)
              return discussionToUpdate ? { ...discussion, topPosition: discussionToUpdate.top } : discussion
            })
          })
          positionUpdates = []
        })
      }
    }
  })
)
