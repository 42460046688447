import { SingleSelect } from "@/components"
import { TriggerElementProps } from "@/components/designSystem/SelectVariations/types"
import { Repository } from "@/models/Repository"
import { useCreateCR } from "@/services/store/useCreateCR.ts"
import { useCallback, useEffect, useMemo, useState } from "react"
import { GitBranch } from "lucide-react"
import { useTranslation } from "react-i18next"
import { BranchType } from "../../types"
import { getBranchValueFromOptions, getRepositoryDefaultBranch } from "../../utils"

interface Props {
  branches: string[]
  type: BranchType
  repositories: Repository[]
}

const BranchTrigger = ({ label, selectItemPlaceholder, disabled }: TriggerElementProps) => {
  return (
    <div className={`mt-1 flex items-center gap-2 ${disabled ? "cursor-auto" : "cursor-pointer"}`} role="button">
      <GitBranch width={16} height={16} className={`${disabled ? "text-darker-fill" : ""}`} />
      <p
        className={`font-semibold ${disabled ? "text-darker-fill" : "text-primary"} overflow-x-hidden text-ellipsis text-nowrap text-xs`}
      >
        {label || selectItemPlaceholder}
      </p>
    </div>
  )
}

export const BranchSelector = ({ branches, type, repositories }: Props) => {
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const createCR = useCreateCR()
  const { t } = useTranslation("Code")

  const disabled: boolean = !createCR.selectedRepositoryId

  const extractOptions = useMemo(() => {
    return branches.map((branch) => ({
      value: branch,
      name: branch
    }))
  }, [branches])

  const handleSelect = (value: string) => {
    if (type === "from") {
      createCR.setFromBranch(value)
    } else {
      createCR.setIntoBranch(value)
    }

    setOpen(false)
  }

  const handleToggle = useCallback(() => {
    if (disabled) return

    setOpen((prev) => !prev)
  }, [setOpen, disabled])

  useEffect(() => {
    const repositoryDefaultBranch = getRepositoryDefaultBranch(createCR.selectedRepositoryId, repositories) || ""
    const defaultBranchValue = getBranchValueFromOptions(repositoryDefaultBranch, extractOptions)

    // setting default branch to the into branch if it is not yet selected
    if (type === "into" && createCR.intoBranch === "" && defaultBranchValue) {
      createCR.setIntoBranch(defaultBranchValue)
    }
  }, [createCR, type, repositories, extractOptions])

  return (
    <SingleSelect
      isOpen={open}
      onClick={handleSelect}
      openToggle={handleToggle}
      optionalParams={{
        CustomTriggerElement: BranchTrigger,
        withSearch: true,
        disabled,
        selectItemPlaceholder: t("SelectBranch")
      }}
      options={extractOptions}
      searchValue={searchValue}
      selectedValue={type === "from" ? createCR.fromBranch : createCR.intoBranch}
      setSearchValue={setSearchValue}
    />
  )
}
