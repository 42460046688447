import { BranchSelector } from "./components/BranchSelector"
import { RepositorySelector } from "./components/RepositorySelector"
import { Loader } from "./components/Loader"
import { BranchStatus } from "./components/BranchStatus"
import { TreeRoot } from "./components/TreeBranch/TreeRoot"
import { useEffect } from "react"
import { useSettings } from "@/services/store/useSettings.ts"
import { useRepositories } from "@/pages/code/hooks/useRepositories.tsx"
import { useBranches } from "@/pages/code/hooks/useBranches.tsx"

export const SideMenu = () => {
  const {
    currentRepository,
    repositories,
    repositoriesLoading,
    setCurrentRepositoryIcon,
    setCurrentRepositoryName,
    currentRepositoryName
  } = useRepositories()
  const { branchesLoading, branches, branchesError, setCurrentBranch, currentBranch } = useBranches({
    currentRepository
  })

  const loading = repositoriesLoading || branchesLoading
  const { setSidebarWidth } = useSettings()

  useEffect(() => {
    setSidebarWidth(320)
  }, [setSidebarWidth])

  if (loading || !currentRepository || !currentBranch) return <Loader />

  return (
    <aside className="sticky top-16 h-0">
      <div className="no-scrollbar h-[calc(100vh-64px)] overflow-y-auto overflow-x-hidden border-r bg-background py-6 pl-12 pr-[22px]">
        <RepositorySelector
          repositories={repositories}
          defaultValue={currentRepositoryName}
          defaultIcon={currentRepository.avatarUrl}
          setCurrentRepositoryName={setCurrentRepositoryName}
          setCurrentRepositoryIcon={setCurrentRepositoryIcon}
          setCurrentBranch={setCurrentBranch}
        />
        <BranchSelector
          key={currentBranch}
          branches={branches}
          error={branchesError}
          defaultValue={currentBranch}
          currentRepositoryName={currentRepositoryName}
          setCurrentBranch={setCurrentBranch}
        />
        <BranchStatus
          repositoryId={currentRepository.id}
          branchName={currentBranch}
          defaultBranch={currentRepository?.defaultBranch}
        />
        <TreeRoot
          key={`${currentBranch} ${currentRepository.fullName}`}
          branchName={currentBranch}
          repositoryId={currentRepository?.id}
        />
      </div>
    </aside>
  )
}
