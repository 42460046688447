import { Entry, FetchContentArgs } from "@/models/FC"
import { BranchInfo } from "@/models/Repository"
import { LucideIcon } from "lucide-react"

interface GetFullRepositoryNameArgs {
  org?: string
  repository?: string
}

interface GetBranchStatusArgs {
  defaultBranch?: string
  branchName?: string
  branchInfo?: BranchInfo
}

interface ComputeContentArgs {
  repositoryId?: string
  branchName?: string
  path: string | null
  type: "file" | "dir"
}

export const getFullRepositoryName = ({ org, repository }: GetFullRepositoryNameArgs): string | undefined => {
  if (!org || !repository) return

  return org + "/" + repository
}

export const getBranchStatus = ({ branchInfo, branchName, defaultBranch }: GetBranchStatusArgs) => {
  const lowerBranchName = branchName?.toLocaleLowerCase()
  const lowerDefaultBranch = defaultBranch?.toLocaleLowerCase()

  if (!lowerBranchName || !lowerDefaultBranch) return null
  if (lowerBranchName === lowerDefaultBranch) return ""
  if (!branchInfo) return null

  return `This branch is ${branchInfo.commitsAhead} commits ahead, ${branchInfo.commitsBehind} commits behind ${defaultBranch}.`
}

export const checkIfForcedOpen = (pathSegments: string[], entry: Entry) => {
  return pathSegments.length > 0 && entry.path === pathSegments[0] && entry.object_type === "dir"
}

export const renderIconBasedOnStatus = (open: boolean, openIcon: LucideIcon, closedIcon: LucideIcon) => {
  return open ? openIcon : closedIcon
}

export const computeContentArgs = ({
  path,
  branchName,
  repositoryId,
  type
}: ComputeContentArgs): FetchContentArgs | null => {
  if (type === "file" && !path) return null

  if (!branchName || !repositoryId) return null

  return {
    repository_id: repositoryId,
    path: path,
    git_ref: {
      branch: branchName
    }
  }
}

export const getFilePathFromUrl = (pathname: string) => {
  const indexOfPathStart = pathname.split("/").findIndex((segment) => segment === "path")

  if (indexOfPathStart === -1) {
    return null
  }

  return pathname
    .split("/")
    .slice(indexOfPathStart + 1)
    .join("/")
}
