import { useMemo } from "react"
import { getBranchStatus } from "../../utils"
import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from "@/components"
import { useFetchBranchInfo } from "@/hooks/queries/useFetchBranchInfo.tsx"

interface Props {
  branchName: string
  defaultBranch?: string
  repositoryId: string
}

export const BranchStatus = ({ branchName, defaultBranch, repositoryId }: Props) => {
  const { data: branchInfo } = useFetchBranchInfo(repositoryId, branchName)
  const computedBranchStatus = useMemo(
    () => getBranchStatus({ branchInfo, branchName, defaultBranch }),
    [defaultBranch, branchName, branchInfo]
  )

  return (
    <div className="my-4">
      <TooltipProvider>
        <Tooltip>
          {computedBranchStatus ? (
            <p className="text-xxs text-primary">
              <TooltipTrigger className="cursor-default">{computedBranchStatus?.substring(0, 30)} ...</TooltipTrigger>
            </p>
          ) : null}

          <TooltipContent>{computedBranchStatus}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}
