import { CRMetadata } from "./components/CRMetadata"
import { NewCRHeader } from "./components/NewCRHeader"
import { useCreateCR } from "@/services/store/useCreateCR.ts"
import { useFetchRepositories } from "@/hooks/queries/useFetchRepositories"
import { useFetchBranches } from "@/hooks/queries/useFetchBranches"
import { useFetchNewCRDiff } from "@/hooks/queries/useFetchNewCRDiff.tsx"
import { DiffContent } from "./components/DiffContent"
import { ExistingCR } from "./components/ExistingCR"
import { RepoAction } from "@/models/Repository"

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return <div className="my-6 flex w-full flex-col bg-background px-12">{children}</div>
}

export function CreateNewCR() {
  document.title = "New Change Request"
  const { selectedRepositoryId, fromBranch, intoBranch } = useCreateCR()
  const {
    data: repositories,
    error: repoError,
    isLoading: repoLoading
  } = useFetchRepositories({ action: RepoAction.WRITE })
  const { data: branches, error: branchesError, isLoading: branchesLoading } = useFetchBranches(selectedRepositoryId)

  const {
    data: newDiff,
    error: diffError,
    isLoading: diffLoading
  } = useFetchNewCRDiff({ repoId: selectedRepositoryId, fromRef: fromBranch, toRef: intoBranch })

  return (
    <Wrapper>
      <NewCRHeader
        branches={branches}
        repositories={repositories}
        branchesError={branchesError}
        repoError={repoError}
        branchesLoading={branchesLoading}
        repoLoading={repoLoading}
      />

      {newDiff?.existing_open_change ? (
        <ExistingCR existingChange={newDiff?.existing_open_change} />
      ) : (
        <>
          <CRMetadata commits={newDiff?.commits} newDiff={newDiff} diffLoading={diffLoading} />
          <DiffContent diff={newDiff} diffError={diffError} diffLoading={diffLoading} />
        </>
      )}
    </Wrapper>
  )
}
