import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { BranchInfo } from "@/models/Repository.ts"

export const useFetchBranchInfo = (repositoryId: string, branch: string) => {
  const { error, isLoading, data } = useQuery<BranchInfo, unknown>({
    queryKey: ["branch", repositoryId, branch],
    queryFn: async () => {
      if (repositoryId) {
        const response = await axiosClient.get(apiRoutes.branches.info(repositoryId, branch))
        return response.data
      }
    },
    enabled: !!repositoryId
  })

  return { error, isLoading, data }
}
