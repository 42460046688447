import { HeaderBox } from "./HeaderBox"
import { NameInput } from "./NameInput"
import { RepositorySelector } from "./RepositorySelector"

import { BranchSelector } from "./BranchSelector"
import { errorHandler } from "@/services/api/helpers"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { Repository } from "@/models/Repository"
import { useTranslation } from "react-i18next"
import { BranchType } from "../../types"
import { LocalStorageService } from "@/lib/localStorageService"
import { useEffect } from "react"
import { useCreateCR } from "@/services/store/useCreateCR.ts"
import { getRepositoryId } from "../../utils"

const repoConditions = (repositories: Repository[], repoError: unknown, repoLoading: boolean) => {
  return (
    <>
      {repoError ? <p>{errorHandler(repoError)}</p> : null}
      {repoLoading ? <SpinnerLoader size="20px" className="pt-1" /> : null}
      {!repoLoading && repositories ? <RepositorySelector repositories={repositories} /> : null}
    </>
  )
}

const branchesConditions = (
  branches: string[],
  branchesError: unknown,
  branchesLoading: boolean,
  type: BranchType,
  repositories: Repository[]
) => {
  return (
    <>
      {branchesError ? <p>{errorHandler(branchesError)}</p> : null}
      {branchesLoading ? <SpinnerLoader size="20px" className="pt-1" /> : null}
      {!branchesLoading && branches ? (
        <BranchSelector branches={branches} type={type} repositories={repositories} />
      ) : null}
    </>
  )
}

interface Props {
  repositories: Repository[]
  repoError: unknown
  repoLoading: boolean
  branches: string[]
  branchesError: unknown
  branchesLoading: boolean
}

const localStorageService = new LocalStorageService()

export const NewCRHeader = ({
  branches,
  branchesError,
  branchesLoading,
  repoError,
  repoLoading,
  repositories
}: Props) => {
  const { t } = useTranslation("CreateCR")
  const { selectedRepositoryId, setSelectedRepositoryId } = useCreateCR()

  useEffect(() => {
    if (!selectedRepositoryId) {
      const repoFromLocalStorage = localStorageService.getItem("repositoryFullName")

      if (repoFromLocalStorage) {
        const repoId = getRepositoryId(repoFromLocalStorage, repositories)
        setSelectedRepositoryId(repoId || "")
      }
    }
  }, [repositories, selectedRepositoryId, setSelectedRepositoryId])

  return (
    <div className="flex w-full gap-1 rounded-md bg-darker-fill p-1">
      <HeaderBox title={t("Title")}>
        <NameInput />
      </HeaderBox>
      <HeaderBox title={t("MergeFrom")}>
        <div className="flex w-fit items-center gap-2">
          {repoConditions(repositories, repoError, repoLoading)}
          <span>:</span>
          {branchesConditions(branches, branchesError, branchesLoading, "from", repositories)}
        </div>
      </HeaderBox>
      <HeaderBox title={t("MergeInto")}>
        <div className="flex w-fit items-center gap-2">
          {repoConditions(repositories, repoError, repoLoading)}
          <span>:</span>
          {branchesConditions(branches, branchesError, branchesLoading, "into", repositories)}
        </div>
      </HeaderBox>
    </div>
  )
}
